<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('admin.company.wkr.calculator')" class="wkr">
    <CCol cols="12" lg="12">
      <CCard class="mb-0 wkr_calculator wizard">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="12" xl="12" class="pt-0 pb-0 text-left">
              <span>{{$t('wkr.Wkr_budget_calculator')}}</span>
            </CCol>         
          </CRow>
        </CCardHeader>
        <CCardBody class="event">
          <CRow v-if="calculatorLoading === true">
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
            </CCol>
          </CRow>
          <CRow v-if="calculatorLoading === false">
            <CCol cols="12" lg="12" class="p-0">
              <b-steps v-model="activeWizardStep"
                        size="is-small"
                        class="wizard_steps h-100"
                        @input="nextWizardStep()"
                        :animated="isAnimated"
                        :rounded="isRounded"
                        :has-navigation="hasNavigation"
                        :icon-prev="prevIcon"
                        :icon-next="nextIcon"
                        :label-position="labelPosition"
                        :mobile-mode="mobileMode">
                                    
                <b-step-item :label="$t('wkr.Discretionary_scope')" :clickable="isStepsClickable" class="wizard_step_item p-0">
                  <CRow class="w-100 m-0">
                    <CCol cols="12" lg="12">
                      <div class="text-center">
                        <h2 class="step_title large m-0">{{$t('wkr.Discretionary_scope')}} {{calculator.wkr_year}}</h2>
                      </div>
                    </CCol>
                    <CCol cols="12" lg="12" class="pt-0 pb-0">
                      <div class="d-flex align-items-center justify-content-center flex-column wizard_step_content">
                        <div class="w-100 d-flex align-items-center justify-content-center flex-grow-1">
                          <div class="flex-1">
                            <CCard class="calculator_data m-0">
                              <CCardBody>
                                <div class="d-flex flex-column align-items-center justify-content-center">
                                  <span class="d-block mb-2">{{$t('wkr.Fiscal_salary_sum')}}</span>                                  
                                  <quick-edit v-model="calculator.fiscal_salary_sum" @input="calculateDiscretionaryScope()" type="number" min="0" mode="ignore" :buttonOkText="$t('Update')" :buttonCancelText="$t('Dismiss')">
                                    <div class="d-flex align-items-center justify-content-center">
                                      <span class="allocation_digit large">&euro;{{formatToCurrency(calculator.fiscal_salary_sum)}}</span><i class="icon edit fas fa-pen"/>
                                    </div>
                                  </quick-edit>
                                </div>
                              </CCardBody>
                            </CCard>
                          </div>
                          <div class="pr-3 pl-3">
                            <i class="fas fa-chevron-right fa-2x"/>
                          </div>
                          <div class="flex-1">
                            <CCard class="calculator_data m-0">
                              <CCardBody>
                                <div class="d-flex flex-column align-items-center justify-content-center">
                                  <span class="d-block mb-2">{{$t('wkr.Available_percentage')}}</span>
                                  <quick-edit v-model="calculator.available_percentage" @input="calculateDiscretionaryScope()" type="number" min="0" max="100" mode="ignore" :buttonOkText="$t('Update')" :buttonCancelText="$t('Dismiss')">
                                    <div class="d-flex align-items-center justify-content-center">
                                      <span class="allocation_digit large">{{calculator.available_percentage}}%</span><i class="icon edit fas fa-pen"/>
                                    </div>
                                  </quick-edit>                                  
                                </div>
                              </CCardBody>
                            </CCard>
                          </div>                          
                          <div class="pr-3 pl-3">
                            <i class="fas fa-chevron-right fa-2x"/>
                          </div>
                          <div class="flex-1">
                            <CCard class="calculator_data m-0">
                              <CCardBody>
                                <div class="d-flex flex-column align-items-center justify-content-center">
                                  <span class="d-block mb-2">{{$t('wkr.Discretionary_scope')}}</span>
                                  <span class="allocation_digit large">&euro;{{formatToCurrency(calculator.wkr_discretionary_scope_available)}}</span>
                                </div>
                              </CCardBody>
                            </CCard>
                          </div>
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                </b-step-item>

                <b-step-item :label="$t('wkr.Allocations')" :clickable="isStepsClickable" class="wizard_step_item p-0">
                  <CCol cols="12" lg="12">
                    <div class="text-center">
                      <h2 class="step_title large m-0">{{$t('wkr.Allocations')}} {{calculator.wkr_year}}</h2>
                    </div>
                  </CCol>                  
                  <CRow class="m-0">
                    <CCol cols="9" lg="9" md="8">
                      <div class="d-flex align-items-center justify-content-center flex-column wizard_step_content">
                        <div class="w-100 d-flex align-items-center justify-content-center flex-column flex-grow-1">
                          <div class="w-100 allocations">
                            <span><b>{{ $t('wkr.Set_allocations') }}</b></span> 

                            <div  v-for="(allocation, index) in calculator.allocations" v-bind:key="index" class="allocation mt-1 d-flex">
                              <div class="w-90">
                                <CRow class="w-100">
                                  <CCol cols="4" lg="4" class="pb-0 pr-0" :class="{'pt-0' : index === 0}">
                                    <label v-if="index === 0">{{$t('common.Name')}}</label>
                                    <CInput @input="updateAllocations('name', index)" type="text" class="m-0" v-model="allocation.allocation_name" :disabled="allocation.allocation_required"></CInput>                              
                                  </CCol>
                                  <CCol cols="4" lg="4" class="pb-0 pr-0" :class="{'pt-0' : index === 0}">
                                    <label v-if="index === 0">{{$t('common.Percentage')}} (%)</label>
                                    <CInput @input="updateAllocations('percentage', index)" type="number" min="0" max="100" class="m-0" v-model="allocation.allocation_percentage"></CInput>
                                  </CCol>
                                  <CCol cols="4" lg="4" class="pb-0 pr-0" :class="{'pt-0' : index === 0}">
                                    <label v-if="index === 0">{{$t('common.Amount')}} (&euro;)</label>
                                    <CInput @input="updateAllocations('amount', index)" type="number" min="0" :max="calculator.wkr_discretionary_scope_available" class="m-0" v-model="allocation.allocation_amount"></CInput>
                                  </CCol>
                                </CRow>                          
                              </div>
                              <div>
                                <div class="h-100 d-flex align-items-end justify-content-end">
                                  <CButton class="m-0" color="primary" @click="allocatePercentageRemaining(index);" :disabled="allocationsPercentageRemaining <= 0 && allocationsAmountRemaining <= 0">
                                    <i class="fas fa-square-plus"/>
                                  </CButton>
                                  <CButton v-if="!allocation.allocation_required" class="ml-2 mr-0" color="primary" @click="removeAllocation(index);">
                                    <i class="fas fa-times"/>
                                  </CButton>
                                </div>
                              </div>
                            </div>

                            <hr class="mt-2_5 mb-2_5">

                            <div class="w-100">
                              <div class="w-90">
                                <CRow class="w-100">
                                  <CCol cols="4" lg="4" class="pt-0 pb-0">
                                    <div class="d-flex align-items-center">
                                      <span>{{$t('wkr.Remaining_discretionary_scope')}}</span>
                                    </div>
                                  </CCol>
                                  <CCol cols="4" lg="4" class="pt-0 pb-0">
                                    <div class="d-flex align-items-center">
                                      <span class="allocation_digit medium" :class="{'negative' : allocationsPercentageRemaining < 0}">{{allocationsPercentageRemaining}}%</span>
                                    </div>
                                  </CCol>                              
                                  <CCol cols="4" lg="4" class="pt-0 pb-0">
                                    <div class="d-flex align-items-center">
                                      <span class="allocation_digit medium" :class="{'negative' : allocationsAmountRemaining < 0}">&euro;{{formatToCurrency(allocationsAmountRemaining)}}</span>
                                    </div>
                                  </CCol>
                                </CRow>
                              </div>
                            </div>

                            <hr class="mt-2_5 mb-2_5">

                            <CRow>
                              <CCol cols="12" lg="12" class="pt-0 pb-0">
                                <CButton color="primary" @click="addAllocation();">
                                  <i class="fas fa-plus mr-1"/>
                                  <span>{{ $t('wkr.Add_allocation') }}</span>
                                </CButton>
                              </CCol>
                            </CRow>
                          </div>
                        </div>
                      </div>
                    </CCol>
                    <CCol cols="3" lg="3" md="4">
                      <div class="chart_container">                                          
                        <vc-donut ref="allocationsDonut"
                                  background="#FFFFFF" 
                                  foreground="#EEEEEE"
                                  :sections="allocationsSections"
                                  :size="75"                                      
                                  unit="%" 
                                  :thickness="25"
                                  has-legend 
                                  legend-placement="bottom"
                                  :total="100"
                                  :start-angle="0" 
                                  :auto-adjust-text-size="true">
                          <span class="allocation_digit medium" :class="{'negative' : allocationsPercentageRemaining < 0}">{{allocationsPercentageUsed}}% {{$t('common.used')}}</span>
                        </vc-donut>
                      </div>            
                    </CCol>
                  </CRow>
                </b-step-item>     

                <b-step-item :label="$t('wkr.Employee_allocations')" :clickable="isStepsClickable" class="wizard_step_item p-0">
                  <CCol cols="12" lg="12">
                    <div class="text-center">
                      <h2 class="step_title large m-0">{{$t('wkr.Employee_allocations')}} {{calculator.wkr_year}}</h2>
                    </div>
                  </CCol>                  
                  <CRow class="m-0">
                    <CCol cols="9" lg="9" md="8">
                      <div class="d-flex align-items-center justify-content-center flex-column wizard_step_content">
                        <div class="w-100 d-flex align-items-center justify-content-center flex-column flex-grow-1">
                          <div class="w-100 allocations">
                            <span><b>{{ $t('wkr.Set_employee_allocations') }}</b></span>
                            <div class="mt-2">
                              <b-switch v-model="manualEmployeeAmountInputActive" size="is-small">{{ $t('wkr.Manually_set_employee_amounts') }}</b-switch>
                            </div>

                            <div v-for="(allocation, index) in calculator.employee_allocations" v-bind:key="index" class="allocation mt-1 d-flex">
                              <div class="w-90">
                                <CRow class="w-100">
                                  <CCol cols="2" lg="2" class="pb-0 pr-0" :class="{'pt-0' : index === 0}">
                                    <label v-if="index === 0">{{$t('common.Name')}}</label>
                                    <CInput type="text" class="m-0" v-model="allocation.allocation_name"></CInput>                              
                                  </CCol>                                  
                                  <CCol class="pb-0 pr-0" :class="{'pt-0' : index === 0}">
                                    <label v-if="index === 0">{{$t('wkr.Min_contract_hours')}}</label>
                                    <CInput @input="updateEmployeeAllocations('min_contract_hours', index)" type="number" min="0" max="40" class="m-0" v-model="allocation.min_contract_hours"></CInput>                              
                                  </CCol>
                                  <CCol class="pb-0 pr-0" :class="{'pt-0' : index === 0}">
                                    <label v-if="index === 0">{{$t('wkr.Max_contract_hours')}}</label>
                                    <CInput @input="updateEmployeeAllocations('max_contract_hours', index)" type="number" min="0" max="40" class="m-0" v-model="allocation.max_contract_hours"></CInput>                              
                                  </CCol>
                                  <CCol class="pb-0 pr-0" :class="{'pt-0' : index === 0}">
                                    <label v-if="index === 0">{{$t('common.Employees')}}</label>
                                    <CInput @input="updateEmployeeAllocations('employee_amount', index)" type="number" min="0" max="40" class="m-0" v-model="allocation.employee_amount" :disabled="!manualEmployeeAmountInputActive"></CInput>                              
                                  </CCol>                                                            
                                  <CCol class="pb-0 pr-0" :class="{'pt-0' : index === 0}">
                                    <label v-if="index === 0">{{$t('common.Percentage')}} (%)</label>
                                    <CInput @input="updateEmployeeAllocations('percentage', index)" type="number" min="0" max="100" class="m-0" v-model="allocation.allocation_percentage"></CInput>
                                  </CCol>
                                  <CCol class="pb-0 pr-0" :class="{'pt-0' : index === 0}">
                                    <label v-if="index === 0">{{$t('wkr.Calculated_amount')}} (&euro;)</label>
                                    <CInput type="number" min="0" :max="employeeDiscretionaryScopeAmount" class="m-0" v-model="allocation.calculated_allocation_amount" disabled></CInput>
                                  </CCol>
                                  <CCol class="pb-0 pr-0" :class="{'pt-0' : index === 0}">
                                    <label v-if="index === 0">{{$t('wkr.Actual_amount')}} (&euro;)</label>
                                    <CInput  @input="updateEmployeeAllocations('actual_amount', index)" type="number" min="0" :max="employeeDiscretionaryScopeAmount" class="m-0" v-model="allocation.actual_allocation_amount"></CInput>
                                  </CCol>
                                </CRow>                          
                              </div>
                              <div>
                                <div class="h-100 d-flex align-items-end justify-content-end">
                                  <CButton class="ml-2 mr-0" color="primary" @click="removeEmployeeAllocation(index);">
                                    <i class="fas fa-times"/>
                                  </CButton>
                                </div>                                
                              </div>
                            </div>

                            <hr class="mt-2_5 mb-2_5">

                            <CRow>
                              <CCol cols="12" lg="12" class="pt-0 pb-0">
                                <CButton color="primary" @click="addEmployeeAllocation();">
                                  <i class="fas fa-plus mr-1"/>
                                  <span>{{ $t('wkr.Add_employee_allocation') }}</span>
                                </CButton>
                              </CCol>
                            </CRow>
                          </div>
                        </div>
                      </div>
                    </CCol>
                    <CCol cols="3" lg="3" md="4">
                      <div class="h-100 d-flex flex-column align-items-center justify-content-center">
                        <div class="mb-3 text-center">
                          <label class="d-block mb-1">{{$t('wkr.Allocated_budget')}} {{calculator.wkr_year}}</label>
                          <span class="allocation_digit medium">&euro;{{formatToCurrency(employeeDiscretionaryScopeAmount)}}</span>
                        </div>                      
                        <div class="mb-3 text-center">
                          <label class="d-block mb-1">{{$t('wkr.Percentage_used')}}</label>
                          <span class="allocation_digit medium" :class="{'negative' : employeeAllocationsPercentageRemaining < 0}">{{employeeAllocationsPercentageUsed}}%</span>
                        </div>
                        <div class="mb-3 text-center">
                          <label class="d-block mb-1">{{$t('wkr.Total_actual_amount_including_fee')}} ({{serviceFeePercentage}}%)</label>
                          <span class="allocation_digit medium" :class="{'negative' : totalCosts > employeeDiscretionaryScopeAmount}">&euro;{{formatToCurrency(totalCosts)}}</span>

                          <div class="d-flex mt-2">
                            <div class="w-50">
                              <label class="d-block m-0">{{$t('wkr.Total_actual_amount')}}</label>
                              <span class="allocation_digit light">&euro;{{formatToCurrency(employeeAllocationsTotalActualAmount)}}</span>
                            </div>
                            <div class="w-50">
                              <label class="d-block m-0">{{$t('wkr.Service_fee')}}</label>
                              <span class="allocation_digit light">&euro;{{formatToCurrency(serviceFee)}}</span>
                            </div>                            
                          </div>
                        </div>
                        <div class="text-center">
                          <label class="d-block mb-1">{{$t('wkr.Remaining_actual_amount')}}</label>
                          <span class="allocation_digit medium" :class="{'negative' : employeeAllocationsRemainingActualAmount < 0}">&euro;{{formatToCurrency(employeeAllocationsRemainingActualAmount)}}</span>
                        </div>                                                                  
                      </div>
                    </CCol>
                  </CRow>
                </b-step-item>

                <b-step-item :label="$t('common.Summary')" :clickable="isStepsClickable" class="wizard_step_item p-0">
                  <div ref="screenshotContent" class="p-3 m-auto calculator_summary">
                    <!-- Intro -->
                    <CRow class="w-100 m-0">
                      <CCol cols="12" lg="12" class="pb-0">
                        <h1 class="mt-0 mb-2"><b>{{$t('wkr.Discretionary_scope')}} - {{calculator.wkr_year}} - {{calculator.company.company_name}}</b></h1>
                        <span class="d-block mb-1">{{$t('wkr.This_report_was_published_by_environment_on', { environment_name: calculator.company.environment_name, publication_date: this.$moment(new Date()).format('DD-MM-YYYY') })}}</span>
                      </CCol>
                    </CRow>
                    <!-- Percentages -->
                    <CRow class="w-100 m-0">
                      <CCol cols="12" lg="12" class="pb-0">
                        <div v-for="(wkr_percentage, index) in calculator.wkr_percentages" :key="index" class="d-flex align-items center" :class="{'mt-2' : index !== 0}">
                          <div style="width:100px">
                            <span class="allocation_digit medium">{{wkr_percentage.percentage}}%</span>
                          </div>
                          <div v-if="wkr_percentage.max_fiscal_salary_sum" class="d-flex align-items-center">
                            <span class="mr-1">{{$t('common.From')}}</span>
                            <span class="mr-1 allocation_digit">&euro;{{wkr_percentage.min_fiscal_salary_sum}}</span>
                            <span class="mr-1">{{$t('common.to')}}</span>
                            <span class="allocation_digit">&euro;{{wkr_percentage.max_fiscal_salary_sum}}</span>
                          </div>
                          <div v-else class="d-flex align-items-center">
                            <span class="mr-1">{{$t('common.From')}}</span>
                            <span class="allocation_digit">&euro;{{wkr_percentage.min_fiscal_salary_sum}}</span>
                          </div>
                        </div>                      
                      </CCol>
                    </CRow>
                    <!-- Break -->
                    <br/>
                    <!-- Digits -->
                    <CRow class="w-100 m-0">
                      <CCol cols="6" lg="6" class="pb-0">
                        <CCard class="calculator_data m-0">
                          <CCardBody class="p-3 text-center">
                            <div class="d-flex flex-column align-items-center justify-content-center">
                              <span class="d-block mb-2 allocation_digit large">&euro;{{formatToCurrency(calculator.fiscal_salary_sum)}}</span>
                              <span>{{$t('wkr.Fiscal_salary_sum')}}</span>
                            </div>
                          </CCardBody>
                        </CCard>                       
                      </CCol>
                      <CCol cols="6" lg="6" class="pb-0">
                        <CCard class="calculator_data m-0">
                          <CCardBody class="p-3 text-center">
                            <div class="d-flex flex-column align-items-center justify-content-center">
                              <span class="d-block mb-2 allocation_digit large">{{totalEmployeeAmount}}</span>
                              <span>{{$t('common.Employees')}}</span>
                            </div>
                          </CCardBody>
                        </CCard>                        
                      </CCol>
                      <CCol cols="6" lg="6" class="pb-0">
                        <CCard class="calculator_data m-0">
                          <CCardBody class="p-3 text-center">
                            <div class="d-flex flex-column align-items-center justify-content-center">
                              <span class="d-block mb-2 allocation_digit large">{{calculator.available_percentage}}%</span>
                              <span>{{$t('wkr.Available_percentage')}}</span>
                            </div>
                          </CCardBody>
                        </CCard>                       
                      </CCol>                      
                      <CCol cols="6" lg="6" class="pb-0">
                        <CCard class="calculator_data m-0">
                          <CCardBody class="p-3 text-center">
                            <div class="d-flex flex-column align-items-center justify-content-center">
                              <span class="d-block mb-2 allocation_digit large">&euro;{{formatToCurrency(calculator.wkr_discretionary_scope_available)}}</span>
                              <span>{{$t('wkr.Discretionary_scope')}}</span>
                            </div>
                          </CCardBody>
                        </CCard>                        
                      </CCol>                                            
                    </CRow>
                    <!-- Break -->
                    <br/>
                    <!-- Allocations -->
                    <CRow class="w-100 m-0">
                      <CCol cols="12" lg="12" class="pb-0">
                        <h2 class="m-0"><b>{{$t('wkr.Allocations')}}</b></h2>
                      </CCol>
                      <CCol cols="6" lg="6" class="pb-0">
                        <div class="h-100 w-100 d-flex align-items-center justify-content-center flex-column flex-grow-1">
                          <div v-for="(allocation, index) in calculator.allocations" v-bind:key="index" class="w-100 d-flex allocation" :class="{'mt-3' : index !== 0}">                            
                            <div class="text-left mr-3 flex-grow-1">
                              <span>{{allocation.allocation_name}}</span>
                            </div>
                            <div class="text-right d-flex align-items-center">                              
                              <span class="allocation_digit medium mr-2">&euro;{{formatToCurrency(allocation.allocation_amount)}}</span>
                              <span>({{allocation.allocation_percentage}}%)</span>
                            </div>
                          </div>
                          <div class="w-100 d-flex mt-3 allocation">                            
                            <div class="text-left mr-3 flex-grow-1">
                              <span>{{$t('wkr.Remaining_discretionary_scope')}}</span>
                            </div>
                            <div class="text-right d-flex align-items-center">
                              <span class="allocation_digit medium mr-2">&euro;{{formatToCurrency(allocationsAmountRemaining)}}</span>
                              <span>({{allocationsPercentageRemaining}}%)</span>
                            </div>
                          </div>                          
                        </div>
                      </CCol>
                      <CCol cols="6" lg="6" class="pb-0">                                                               
                        <vc-donut ref="allocationsDonut"
                                  background="#FFFFFF" 
                                  foreground="#EEEEEE"
                                  :sections="allocationsSections"
                                  :size="150"                                      
                                  unit="px" 
                                  :thickness="35"
                                  has-legend 
                                  legend-placement="right"
                                  :total="100"
                                  :start-angle="0" 
                                  :auto-adjust-text-size="true">                            
                        </vc-donut>
                      </CCol>
                    </CRow>                          
                    <!-- Break -->
                    <br/><br/>
                    <hr class="m-0">                
                    <br/><br/>
                    <!-- Text -->
                    <CRow class="w-100 m-0">
                      <CCol cols="12" lg="12" class="pt-0 pb-0">
                        <h2 class="mt-0 mb-2"><b>{{$t('wkr.Environment_budget', { environment_name: calculator.company.environment_name })}}</b></h2>                        
                        <span>{{$t('wkr.Employees_will_recieve_monthly_loyalty_points', { environment_name: calculator.company.environment_name })}}</span>
                      </CCol>
                    </CRow>
                    <!-- Break -->
                    <br/>
                    <!-- Remaining budget -->
                    <CRow v-if="employeeAllocationsRemainingActualAmount > 0" class="w-100 m-0">
                      <CCol cols="12" lg="12" class="pt-0 pb-0">                      
                        <span>{{$t('wkr.You_have_allocated', { environment_name: calculator.company.environment_name, total_costs: totalCosts, remaining_actual_amount: employeeAllocationsRemainingActualAmount })}}</span>                                                    
                      </CCol>
                    </CRow>
                    <!-- Break -->
                    <br/>
                    <!-- Employee Allocations -->
                    <CRow class="w-100 m-0">
                      <CCol v-for="(allocation, index) in calculator.employee_allocations" v-bind:key="index" cols="12" lg="12" class="pt-0">                        
                        <hr v-if="index !== 0" class="mt-0 mb-3"> 
                        <div class="d-flex">
                          <div class="flex-grow-1 d-flex">
                            <div class="d-flex align-items-center pr-4">
                              <i class="fas fa-users fa-3x"/>
                            </div>
                            <div>
                              <span class="d-block mb-1"><b>{{allocation.allocation_name}}</b></span>
                              <span class="d-block mb-1">{{allocation.employee_amount}} {{$t('common.employees')}}</span>
                              <span>{{allocation.min_contract_hours}} - {{allocation.max_contract_hours}} {{$t('common.hours')}}</span>
                            </div>
                          </div>
                          <div class="flex-grow-1 d-flex justify-content-center flex-column">
                            <span class="allocation_digit small d-block mb-2">&euro;{{allocation.actual_allocation_amount}} {{$t('wkr.per_month')}}</span>
                            <span class="allocation_digit small">&euro;{{parseInt(allocation.actual_allocation_amount * 12)}} {{$t('wkr.per_year')}}</span>
                          </div>                          
                        </div>
                      </CCol>
                    </CRow>
                    <!-- Break -->
                    <br/>      
                    <!-- Text -->
                    <CRow class="w-100 m-0">
                      <CCol cols="12" lg="12" class="pb-0">                                          
                        <span>{{$t('wkr.By_using_environment', { environment_name: calculator.company.environment_name })}}</span>
                      </CCol>
                    </CRow>
                    <!-- Break -->
                    <br/>
                    <!-- Costs -->
                    <CRow class="w-100 m-0">
                      <CCol cols="4" lg="4" class="pb-0">
                        <CCard class="calculator_data h-100 m-0">
                          <CCardBody class="p-3 text-center">
                            <div class="d-flex flex-column align-items-center justify-content-center">
                              <span class="d-block mb-2 allocation_digit large">&euro;{{formatToCurrency(employeeAllocationsTotalActualAmount)}}</span>
                              <span>{{$t('wkr.Value_gift_credit')}}</span>
                            </div>
                          </CCardBody>
                        </CCard>                       
                      </CCol>
                      <CCol cols="4" lg="4" class="pb-0">
                        <CCard class="calculator_data h-100 m-0">
                          <CCardBody class="p-3 text-center">
                            <div class="d-flex flex-column align-items-center justify-content-center">
                              <span class="d-block mb-2 allocation_digit large">&euro;{{formatToCurrency(serviceFee)}}</span>
                              <span>{{$t('wkr.Net_service_costs_environment', { environment_name: calculator.company.environment_name })}}</span>
                            </div>
                          </CCardBody>
                        </CCard>                        
                      </CCol>
                      <CCol cols="4" lg="4" class="pb-0">
                        <CCard class="calculator_data h-100 m-0">
                          <CCardBody class="p-3 text-center">
                            <div class="d-flex flex-column align-items-center justify-content-center">
                              <span class="d-block mb-2 allocation_digit large">&euro;{{formatToCurrency(totalCosts)}}</span>
                              <span>{{$t('wkr.Total_costs_environment', { environment_name: calculator.company.environment_name })}}</span>
                            </div>
                          </CCardBody>
                        </CCard>                        
                      </CCol>                                            
                    </CRow>
                    <!-- Break -->
                    <br/>
                    <!-- Text -->
                    <CRow class="w-100 m-0">
                      <CCol cols="12" lg="12" class="pb-0">                                          
                        <span>{{$t('wkr.This_calculation_has_been_prepared')}}</span>
                      </CCol>
                    </CRow>
                    <!-- Break -->
                    <br/><br/>
                    <!-- Environment Footer -->
                    <CRow class="w-100 m-0">
                      <CCol cols="12" lg="12" class="pt-0 pb-0">
                        <div class="d-flex align-items center">
                          <div class="d-flex align-items-center mr-4">
                            <img :src="getLogoSrc(calculator.company.environment_tag)" style="height:50px;"/>                           
                          </div>
                          <div class="d-flex flex-column justify-content-center">
                            <span class="d-block mb-1">{{calculator.company.environment_contact_email}}</span>
                            <span>{{calculator.company.environment_website}}</span>
                          </div>                          
                        </div>                         
                      </CCol>
                    </CRow>
                  </div>
                </b-step-item>                  

                <template v-if="customNavigation" slot="navigation" slot-scope="{previous, next}">
                  <CRow class="w-100 m-0">
                    <CCol cols="6" lg="6" class="pt-0 pb-0 text-left">
                      <CButton color="secondary" class="m-0" :disabled="previous.disabled" @click.prevent="previous.action">
                        <span><i class="fa-solid fa-chevron-left mr-1"/>{{$t('common.Previous')}}</span>
                      </CButton>
                    </CCol>
                    <CCol cols="6" lg="6" class="pt-0 pb-0 text-right">
                      <CButton v-if="activeWizardStep !== 3"
                              color="secondary"
                              class="m-0"
                              :disabled="next.disabled || 
                                          (activeWizardStep === 1 && (allocationsPercentageRemaining < 0 || allocationsAmountRemaining < 0)) ||
                                          (activeWizardStep === 2 && (employeeAllocationsPercentageRemaining < 0 || employeeAllocationsRemainingActualAmount < 0))
                                          "
                              @click.prevent="next.action">
                        <span>{{$t('common.Next')}}<i class="fa-solid fa-chevron-right ml-1"/></span>
                      </CButton>                                                                        
                      <CButton v-if="activeWizardStep === 3" color="primary" class="mr-2" @click="makeScreenshot();">
                        <span><i class="fa-solid fa-image mr-1"></i>{{$t('wkr.Save_wkr_calculation_as_image')}}</span>
                      </CButton>
                      <CButton v-if="activeWizardStep === 3" color="primary" class="m-0" @click="updateCompanyWkrCalculationData();">
                        <span><i class="fa-solid fa-save mr-1"></i>{{$t('wkr.Save_wkr_calculation')}}</span>
                      </CButton>                  
                    </CCol>                      
                  </CRow>
                </template>
              </b-steps>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>            
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import { debounce } from "debounce";
import VueLodash from 'vue-lodash'
import lodash from 'lodash'

import loadingSpinner from '@/components/common/loadingSpinner.vue';
import QuickEdit from 'vue-quick-edit';
import noPermission from '@/components/common/noPermission.vue';

export default {
  name: 'Moment',
  components: {
    debounce,
    VueLodash,
    lodash,    
    loadingSpinner,
    QuickEdit,
    noPermission
  },
  watch: { 
    '$route.params.id': {
      handler() { 
        this.loyaltyCompanyWkrIdExternal = this.$route.params.id;
        // Get the company WKR calculation data
        this.getCompanyWkrCalculationData();
      },
      deep: true,
      immediate: true
    }
  },  
  data() {
    return {
      loyaltyCompanyWkrIdExternal: null,
      platformPermissions: [],
      platformPermissionsLoaded: false,    
      calculatorLoading: false,
      calculatorLoaded: false,
      calculator: {
        fiscal_salary_sum: null,
        available_percentage: null,
        wkr_discretionary_scope: null,
        wkr_discretionary_scope_scale_1: null,
        wkr_discretionary_scope_scale_2: null,
        wkr_discretionary_scope_available: null,
        wkr_discretionary_scope_remaining: null,
        wkr_total_employee_count: null,
        wkr_percentages: [],
        allocations: [],
        employee_allocations: []
      },
      allocationsSections: [],
      allocationsPercentageUsed: 100,
      allocationsPercentageRemaining: 0,
      allocationsAmountRemaining: 0,            
      allocationsValid: 0,
      employeeDiscretionaryScopeAmount: 0,      
      employeeAllocationsPercentageUsed: 0,
      employeeAllocationsPercentageRemaining: 0,
      employeeAllocationsTotalCalculatedAmount: 0,
      employeeAllocationsTotalActualAmount: 0,
      employeeAllocationsRemainingActualAmount: 0,
      employeeAllocationsValid: 0,      
      manualEmployeeAmountInputActive: false,
      totalEmployeeAmount: 0,
      serviceFee: 0,
      serviceFeePercentage: 0,
      totalCosts: 0,
      activeWizardStep: 0,
      isAnimated: false,
      isRounded: true,
      isStepsClickable: false,
      hasNavigation: true,
      customNavigation: true,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist' 
    }
  },
  methods: {
    getLogoSrc(environmentTag) {
      return `${this.cdnBaseUrl}/base/dashboard/logo/${environmentTag}_logo.png`;
    },     
    nextWizardStep() {
      if(this.activeWizardStep === 1) {
        // Check the employee allocations
        if(this.calculator.allocations.length > 0) {
          // Loop through allocations
          for(var a = 0; a < this.calculator.allocations.length; a++) {
            let allocation = this.calculator.allocations[a];
            // Calculate the new allocation amount           
            allocation.allocation_amount = parseInt((this.calculator.wkr_discretionary_scope_available * (allocation.allocation_percentage / 100)));
          }
        }
        // Check the allocations
        this.checkAllocations();        
      } else if(this.activeWizardStep === 2) {
        // Update the employeeDiscretionaryScopeAmount value
        this.employeeDiscretionaryScopeAmount = this.calculator.allocations[0].allocation_amount;
        // Check if there are employee allocations set already
        if(this.calculator.employee_allocations.length === 0) {
          // Calculate the default employee allocation amount
          let calculatedEmployeeAllocationAmount = parseInt((this.employeeDiscretionaryScopeAmount / this.calculator.wkr_total_employee_count) / 12);
          // Add the default employee allocation to the employee_allocations
          this.calculator.employee_allocations.push({
            allocation_name: 'Allocation ' + (this.calculator.employee_allocations.length + 1),
            min_contract_hours: 0,
            max_contract_hours: 40,
            employee_amount: this.calculator.wkr_total_employee_count,
            allocation_percentage: 100,
            calculated_allocation_amount: calculatedEmployeeAllocationAmount,
            actual_allocation_amount: calculatedEmployeeAllocationAmount
          });
          // Check the employee allocations
          this.checkEmployeeAllocations();           
        } else {
          // Loop through employee allocations
          for(var a = 0; a < this.calculator.employee_allocations.length; a++) {
            let employeeAllocation = this.calculator.employee_allocations[a];
            // Calculate the new employee allocation amount           
            let newCalculatedEmployeeAllocationAmount = parseInt(((this.employeeDiscretionaryScopeAmount * (employeeAllocation.allocation_percentage / 100)) / employeeAllocation.employee_amount) / 12);                        
            // Update the employee allocation amounts of the allocation
            if(employeeAllocation.calculated_allocation_amount === employeeAllocation.actual_allocation_amount) {
              employeeAllocation.calculated_allocation_amount = newCalculatedEmployeeAllocationAmount;
              employeeAllocation.actual_allocation_amount = newCalculatedEmployeeAllocationAmount;
            } else {
              employeeAllocation.calculated_allocation_amount = newCalculatedEmployeeAllocationAmount;
            }
          }
          // Check the employee allocations
          this.checkEmployeeAllocations(); 
        }       
      }
    }, 
    getCompanyWkrCalculationData() {
      // Start the loader
      if(!this.calculatorLoaded) this.calculatorLoading = true;
      // Get the event data
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/company/wkr/' + this.loyaltyCompanyWkrIdExternal + '/calculation')
      .then(res => {
        this.calculator = res.data.data;
        // Update the serviceFeePercentage value
        this.serviceFeePercentage = this.calculator.company.wkr_service_fee_percentage;
        // Reset the allocationsSections array
        this.allocationsSections = [];
        // Loop through the allocations and add them to the allocationsSections array
        for(var a = 0; a < this.calculator.allocations.length; a++) {
          this.allocationsSections.push({
            label: this.calculator.allocations[a].allocation_name,
            value: this.calculator.allocations[a].allocation_percentage          
          });
        }
        // Stop the loader
        this.calculatorLoading = false;
        // Update the calculatorLoaded value
        this.calculatorLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    updateCompanyWkrCalculationData() {
      let params = {};
      params.calculationData = this.calculator;
      // Loop through the allocations and set the required value
      for(var a = 0; a < params.calculationData.allocations.length; a++) {
        (params.calculationData.allocations[a].allocation_required === true) ? params.calculationData.allocations[a].required = 'Y' : params.calculationData.allocations[a].required = 'N';
      }
      // Update the company WKR data
      axios.put(process.env.VUE_APP_API_URL + '/v1/loyalty/company/wkr/' + this.loyaltyCompanyWkrIdExternal + '/calculation', params)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('wkr.Calculation_saved'), type: 'is-success', duration: 2000 });
        // Update the calculation data
        this.getCompanyWkrCalculationData();
      })
      .catch(err => {
        console.error(err); 
      });
    },
    calculateDiscretionaryScope() {
      let params = {};
      params.fiscal_salary_sum = parseInt(this.calculator.fiscal_salary_sum);
      params.available_percentage = parseFloat(this.calculator.available_percentage);
      params.wkr_percentages = this.calculator.wkr_percentages;
      // Calculate the discretionary scope
      axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/company/wkr/scope/calculate', params)
      .then(res => {
        let calculatedScope = res.data.data;
        // Update the calculator values
        this.calculator.fiscal_salary_sum = calculatedScope.fiscal_salary_sum;              
        this.calculator.available_percentage = calculatedScope.available_percentage;
        this.calculator.wkr_discretionary_scope = calculatedScope.wkr_discretionary_scope;
        this.calculator.wkr_discretionary_scope_scale_1 = calculatedScope.wkr_discretionary_scope_scale_1;
        this.calculator.wkr_discretionary_scope_scale_2 = calculatedScope.wkr_discretionary_scope_scale_2;
        this.calculator.wkr_discretionary_scope_available = calculatedScope.wkr_discretionary_scope_available;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    updateAllocations: lodash.debounce(async function(change, index) {
      let allocationChanged = this.calculator.allocations[index];
      // Update the allocation amount when the percentage was changed      
      if(change === 'percentage') {
        allocationChanged.allocation_percentage = parseFloat(allocationChanged.allocation_percentage);
        allocationChanged.allocation_amount = parseInt(this.calculator.wkr_discretionary_scope_available * (allocationChanged.allocation_percentage / 100));
      }
      // Update the allocation percentage when the amount was changed
      else if(change === 'amount') {
        allocationChanged.allocation_amount = parseInt(allocationChanged.allocation_amount);
        allocationChanged.allocation_percentage = parseFloat(((allocationChanged.allocation_amount / this.calculator.wkr_discretionary_scope_available) * 100).toFixed(2));
      }      
      // Check the allocations
      this.checkAllocations();
    }, 500),
    checkAllocations() {
      // Set default variables
      let percentageUsed = 0;
      let percentageRemaining = 100;
      let amountUsed = 0;      

      // Loop through the allocations set
      for(var a = 0; a < this.calculator.allocations.length; a++) {
        // Update the percentageUsed variable
        percentageUsed = parseFloat((percentageUsed + this.calculator.allocations[a].allocation_percentage).toFixed(2));
        // Update the percentageRemaining variable
        percentageRemaining = parseFloat((percentageRemaining - this.calculator.allocations[a].allocation_percentage).toFixed(2));
        // Update the amountUsed variable
        amountUsed = parseInt(amountUsed + this.calculator.allocations[a].allocation_amount);
      }
      // Update the allocationsPercentageUsed value
      this.allocationsPercentageUsed = percentageUsed;
      // Update the allocationsPercentageRemaining value
      this.allocationsPercentageRemaining = percentageRemaining;
      // Update the allocationsAmountRemaining value
      this.allocationsAmountRemaining = this.calculator.wkr_discretionary_scope_available - amountUsed;
      // Update the allocationsValid value
      (this.allocationsPercentageUsed <= 100) ? this.allocationsValid = true : this.allocationsValid = false;
      // Update the chart if the allocations are valid
      if(this.allocationsValid) this.updateAllocationChart();
      // Add remaining percentage section to chat if necessary
      if(this.allocationsPercentageRemaining > 0) {
        this.allocationsSections.push({
          label: this.$t('wkr.Remaining_discretionary_scope'),
          value: this.allocationsPercentageRemaining,
          color: '#EEEEEE'
        });        
      }
    },
    updateAllocationChart() {
      // Set empty newAllocationsSections array
      let newAllocationsSections = [];
      // Loop through the allocations
      for(var a = 0; a < this.calculator.allocations.length; a++) {        
        // Set default newAllocationsSection object
        let newAllocationsSection = { label: 'Allocation ' + this.calculator.allocations.length, value: 0 };
        // Update the value if the allocation_percentage is set
        if(this.calculator.allocations[a].allocation_percentage) newAllocationsSection.value = this.calculator.allocations[a].allocation_percentage;
        // Update the label if the allocation_name is set
        if(this.calculator.allocations[a].allocation_name) newAllocationsSection.label = this.calculator.allocations[a].allocation_name;        
        // Add the newAllocationsSection object to the newAllocationsSections array
        newAllocationsSections.push(newAllocationsSection);
      }
      // Update the allocationsSections
      this.allocationsSections = newAllocationsSections;
    },
    allocatePercentageRemaining(index) {
      // Add the remaining percentage to the allocation
      this.calculator.allocations[index].allocation_percentage = parseFloat(this.calculator.allocations[index].allocation_percentage) + parseFloat(this.allocationsPercentageRemaining);
      // Add the remaining amount to the allocation
      this.calculator.allocations[index].allocation_amount = parseFloat(this.calculator.allocations[index].allocation_amount) + parseFloat(this.allocationsAmountRemaining);
      // Check the allocations
      this.checkAllocations();
    },
    addAllocation() {
      // Add empty object to allocations array
      this.calculator.allocations.push({
        allocation_name: 'Allocation ' + (this.calculator.allocations.length + 1),
        allocation_percentage: 0,
        allocation_amount: 0,
        allocation_required: false
      });
      // Check the allocations
      this.checkAllocations();
    },
    removeAllocation(index) {
      let allocationIdExternal = this.calculator.allocations[index].loyalty_company_wkr_allocation_id_external;
      // Check if the loyalty_company_wkr_allocation_id_external of the allocation has been set
      if(allocationIdExternal) {
        // Delete the allocation
        axios.delete(process.env.VUE_APP_API_URL + '/v1/loyalty/company/wkr/' + this.loyaltyCompanyWkrIdExternal + '/allocation/' + allocationIdExternal)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('wkr.Allocation_removed'), type: 'is-success', duration: 2000 });
          // Remove allocation from allocations array
          this.calculator.allocations.splice(index, 1);
          // Check the allocations
          this.checkAllocations();          
        })
        .catch(err => {
          console.error(err);
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        });
      } else {
        // Remove allocation from allocations array
        this.calculator.allocations.splice(index, 1);
        // Check the allocations
        this.checkAllocations();        
      }
    },
    updateEmployeeAllocations: lodash.debounce(async function(change, index) {
      let employeeAllocationChanged = this.calculator.employee_allocations[index];
      // Update the allocation employee amount when the contract hours are changed and the manual input is disabled
      if(this.manualEmployeeAmountInputActive === false && (change === 'min_contract_hours' || change === 'max_contract_hours')) this.getEmployeeAmount(index);
      // Parse the allocation employee amount to an int when it is changed
      if(change === 'employee_amount') employeeAllocationChanged.employee_amount = parseInt(employeeAllocationChanged.employee_amount);      
      // Parse the allocation percentage to an int when it is changed
      if(change === 'percentage') employeeAllocationChanged.allocation_percentage = parseFloat(employeeAllocationChanged.allocation_percentage);      
      // Update the allocation calculated allocation amount when the employee amounnt of the percentage has changed
      if(change === 'employee_amount' || change === 'percentage') {
        // Set default calculatedEmployeeAllocationAmount variable
        let calculatedEmployeeAllocationAmount = 0;
        // Update the calculatedEmployeeAllocationAmount value if the employee amount is set
        if(employeeAllocationChanged.employee_amount) {
          calculatedEmployeeAllocationAmount = parseInt(((this.employeeDiscretionaryScopeAmount * (employeeAllocationChanged.allocation_percentage / 100)) / employeeAllocationChanged.employee_amount) / 12);
        }
        // Update the employee allocation amounts of the allocation
        employeeAllocationChanged.calculated_allocation_amount = calculatedEmployeeAllocationAmount;
        employeeAllocationChanged.actual_allocation_amount = calculatedEmployeeAllocationAmount;
      }
      // Parse the actual allocation amount to an int when it is changed
      if(change === 'actual_amount') employeeAllocationChanged.actual_allocation_amount = parseInt(employeeAllocationChanged.actual_allocation_amount);
      // Check the employee allocations 
      this.checkEmployeeAllocations();
    }, 500),
    checkEmployeeAllocations() {
      // Set default variables
      let employeePercentageUsed = 0;
      let employeePercentageRemaining = 100;
      let totalCalculatedAmount = 0;
      let totalActualAmount = 0;
      let totalEmployeeAmount = 0;           

      // Loop through the employee allocations set
      for(var a = 0; a < this.calculator.employee_allocations.length; a++) {
        let employeeAllocation = this.calculator.employee_allocations[a];
        // Update the employeePercentageUsed variable
        employeePercentageUsed = parseFloat((employeePercentageUsed + employeeAllocation.allocation_percentage).toFixed(2));
        // Update the employeePercentageRemaining variable
        employeePercentageRemaining = parseFloat((employeePercentageRemaining - employeeAllocation.allocation_percentage).toFixed(2));
        // Update the totalCalculatedAmount variable
        totalCalculatedAmount = parseInt(totalCalculatedAmount + ((employeeAllocation.calculated_allocation_amount * employeeAllocation.employee_amount) * 12));
        // Update the totalActualAmount variable
        totalActualAmount = parseInt(totalActualAmount + ((employeeAllocation.actual_allocation_amount * employeeAllocation.employee_amount) * 12));
        // Update the totalEmployeeAmount variable
        totalEmployeeAmount = parseInt(totalEmployeeAmount + employeeAllocation.employee_amount);   
      }

      // Update the employeeAllocationsPercentageUsed value
      this.employeeAllocationsPercentageUsed = employeePercentageUsed;
      // Update the employeeAllocationsPercentageRemaining value
      this.employeeAllocationsPercentageRemaining = employeePercentageRemaining;
      // Update the employeeAllocationsTotalCalculatedAmount value
      this.employeeAllocationsTotalCalculatedAmount = totalCalculatedAmount;
      // Update the employeeAllocationsTotalActualAmount value
      this.employeeAllocationsTotalActualAmount = totalActualAmount;
      // Update the serviceFee value
      this.serviceFee = parseInt(totalActualAmount * (this.serviceFeePercentage / 100));
      // Update the totalCosts value
      this.totalCosts = totalActualAmount + this.serviceFee;
      // Update the employeeAllocationsRemainingActualAmount value
      this.employeeAllocationsRemainingActualAmount = this.employeeDiscretionaryScopeAmount - this.totalCosts;
      // Update the totalEmployeeAmount value
      this.totalEmployeeAmount = totalEmployeeAmount;
      // Update the employeeAllocationsValid value
      (this.employeeAllocationsPercentageUsed <= 100) ? this.employeeAllocationsValid = true : this.employeeAllocationsValid = false;   
    },
    addEmployeeAllocation() {
      // Add empty object to employee_allocations array
      this.calculator.employee_allocations.push({
        allocation_name: 'Allocation ' + (this.calculator.employee_allocations.length + 1),
        min_contract_hours: 0,
        max_contract_hours: 0,
        employee_amount: 0,
        allocation_percentage: 0,
        calculated_allocation_amount: 0,
        actual_allocation_amount: 0
      });
      // Check the employee allocations
      this.checkEmployeeAllocations();
    },
    removeEmployeeAllocation(index) {
      let employeeAllocationIdExternal = this.calculator.employee_allocations[index].loyalty_company_wkr_employee_allocation_id_external;
      // Check if the loyalty_company_wkr_employee_allocation_id_external of the employee allocation has been set
      if(employeeAllocationIdExternal) {
        // Delete the employee allocation
        axios.delete(process.env.VUE_APP_API_URL + '/v1/loyalty/company/wkr/' + this.loyaltyCompanyWkrIdExternal + '/employee/allocation/' + employeeAllocationIdExternal)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('wkr.Employee_allocation_removed'), type: 'is-success', duration: 2000 });        
          // Remove allocation from employee_allocations array
          this.calculator.employee_allocations.splice(index, 1);
          // Check the employee allocations
          this.checkEmployeeAllocations();          
        })
        .catch(err => {
          console.error(err);
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        });
      } else {
        // Remove allocation from employee_allocations array
        this.calculator.employee_allocations.splice(index, 1);
        // Check the employee allocations
        this.checkEmployeeAllocations();
      }
    },
    getEmployeeAmount(index) {
      let params = {};
      params.min_contract_hours = parseInt(this.calculator.employee_allocations[index].min_contract_hours);
      params.max_contract_hours = parseInt(this.calculator.employee_allocations[index].max_contract_hours);
      
      axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/company/wkr/employees', params)
      .then(res => {        
        let employeeAmount = res.data.data.total_employees;

        this.calculator.employee_allocations[index].employee_amount = employeeAmount;

        if(employeeAmount) this.calculator.employee_allocations[index].calculated_allocation_amount = parseInt(((this.employeeDiscretionaryScopeAmount * (this.calculator.employee_allocations[index].allocation_percentage / 100)) / employeeAmount) / 12);        
        else this.calculator.employee_allocations[index].calculated_allocation_amount = 0;
        // Check the employee allocations 
        this.checkEmployeeAllocations();        
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },    
    formatToCurrency(data) {
      return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format((data));
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {      
        this.modules = res.data.data;    
      })
      .catch(err => {
        console.error(err); 
      });
    },
    makeScreenshot() {
      window.scroll(0,0);
      // Get the HTML to print
      const content = this.$refs.screenshotContent;
      // Set the html2canvas options
      const options = { type: "dataURL", useCORS: true };
      
      setTimeout(function() {        
        this.printScreenshot(content, options);
      }.bind(this), 500);
    },
    async printScreenshot(content, options) {
      // Create canvas for printing
      const printCanvas = await html2canvas(content, options);
      // Creata a link element to download the generated canvas
      const link = document.createElement("a");
      // Set the attributes of the link element
      link.setAttribute("download", this.$t('wkr.Wkr_discretionary_scope_calculation') + "_" + this.calculator.wkr_year + "_" + this.calculator.company.company_name + "_" + new Date().toLocaleString() + ".png");
      link.setAttribute("href", printCanvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
      // Simulate click on link element
      link.click();
    }
  },
  mounted: function() {
    this.getPlatformPermissions();
    this.checkModules();   
  }
}
</script>
